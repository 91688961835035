import axios from 'axios';

export default {
  getSystemSettings() {
    return axios.get('/api/SystemSettings/GetSystemSettings', {
      params: {

      }
    });
  },
  updateSystemSettings(settings) {
    return axios.post('/api/SystemSettings/UpdateSystemSettings', { ...settings });
  },
};

